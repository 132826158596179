import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { AdminEventSchedulePageComponent } from './pages/admin-event-schedule-page/admin-event-schedule-page.component';
import { AdminSubscribersPageComponent } from './pages/admin-subscribers-page/admin-subscribers-page.component';
import { SpinnerComponent } from '../shared/components/spinner/spinner.component';
import { EventScheduleCreatePageComponent } from './pages/event-schedule-create-page/event-schedule-create-page.component';
import { EventScheduleEditformComponent } from './components/event-schedule-editform/event-schedule-editform.component';
import { SubscriberEditformComponent } from './components/subscriber-editform/subscriber-editform.component';
import { SubscriberCreatePageComponent } from './pages/subscriber-create-page/subscriber-create-page.component';
import { FunnelEditformComponent } from './components/funnel-editform/funnel-editform.component';
import { AdminFunnelPageComponent } from './pages/admin-funnel-page/admin-funnel-page.component';
import { FunnelCreatePageComponent } from './pages/funnel-create-page/funnel-create-page.component';
import { AdminTopicsPageComponent } from './pages/admin-topics-page/admin-topics-page.component';
import { TopicsCreatePageComponent } from './pages/topics-create-page/topics-create-page.component';
import { TopicsEditformComponent } from './components/topics-editform/topics-editform.component';
import { AdminAdsViewEventScheduleComponent } from './pages/admin-ads-view-event-schedule/admin-ads-view-event-schedule.component';
import { AdminPayStatsEventScheduleComponent } from './pages/admin-pay-stats-event-schedule/admin-pay-stats-event-schedule.component';
import { AdminToDoEventScheduleComponent } from './pages/admin-to-do-event-schedule/admin-to-do-event-schedule.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { EventStatusComponent } from './components/event-status/event-status.component';
import { EventPopUpComponent } from './components/event-pop-up/event-pop-up.component';
import { EventAdminTabsComponent } from './components/event-admin-tabs/event-admin-tabs.component';
import { EventSchedulesPageComponent } from './pages/event-schedules-page/event-schedules-page.component';
import { ChangeStatusModalComponent } from './components/change-status-modal/change-status-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UpcomingCallsComponent } from './components/upcoming-calls/upcoming-calls.component';
import { AdminRoomsPageComponent } from '../core/pages/admin-rooms-page/admin-rooms-page.component';
import { PopUpIframeComponent } from './components/pop-up-iframe/pop-up-iframe.component';
import { AdminEventInfosPageComponent } from './pages/admin-event-infos-page/admin-event-infos-page.component';
import { EventInfosCreatePageComponent } from './pages/event-infos-create-page/event-infos-create-page.component';
import { EventInfosEditformComponent } from './components/event-infos-editform/event-infos-editform.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { MatInputModule } from '@angular/material/input';
import { ChangePayStatsPopUpComponent } from './components/change-pay-stats-pop-up/change-pay-stats-pop-up.component';
import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';
import { StatisticsGridComponent } from './components/statistics-grid/statistics-grid.component';
import { ClientEventSchedulesComponent } from './pages/client-event-schedules/client-event-schedules.component';
import { EventScheduleClientCreatePageComponent } from './pages/event-schedule-client-create-page/event-schedule-client-create-page.component';
import { ClientEventScheduleEditFormComponent } from './components/client-event-schedule-edit-form/client-event-schedule-edit-form.component';
import { EventSchedulesTitleComponent } from './components/event-schedules-title/event-schedules-title.component';
import { DraftsPageComponent } from './pages/drafts-page/drafts-page.component';
import { ClientMonthTableComponent } from './components/client-month-table/client-month-table.component';
import { ClientDraftsMonthTableComponent } from './components/client-drafts-month-table/client-drafts-month-table.component';
import { AdminSubmitsPageComponent } from './pages/admin-submits-page/admin-submits-page.component';
import { SubmitsMonthTableComponent } from './components/submits-month-table/submits-month-table.component';
import { FinancialsEsComponent } from './pages/financials-es/financials-es.component';
import { SanitizeHtmlPipe } from './helpers/html-sanitizer';
import { BackendTeamsComponent } from './components/backend-teams/backend-teams.component';
import { BackendPageComponent } from './pages/backend-page/backend-page.component';
import { ActivityLogModalComponent } from './components/activity-log-modal/activity-log-modal.component';
import { AeStatsComponent } from './components/ae-stats/ae-stats.component';
import { AeStatsPageComponent } from './pages/ae-stats-page/ae-stats-page.component';
import { AeStatsSumUpComponent } from './components/ae-stats-sum-up/ae-stats-sum-up.component';
import { AssignCallsPageComponent } from './pages/assign-calls-page/assign-calls-page.component';
import { AssignCallsTableComponent } from './components/assign-calls-table/assign-calls-table.component';
import { ChangeAssignerPopupComponent } from './components/change-assigner-popup/change-assigner-popup.component';
import { VenueSpecificNotesComponent } from './modals/venue-specific-notes/venue-specific-notes.component';
import { ReferralSourcesPageComponent } from './pages/referral-sources-page/referral-sources-page.component';
import { ReferralSourcesCreatePageComponent } from './pages/referral-sources-create-page/referral-sources-create-page.component';
import { ReferralSourcesEditformComponent } from './components/referral-sources-editform/referral-sources-editform.component';
import { CallerStatsPageComponent } from './pages/caller-stats-page/caller-stats-page.component';
import { CallersStatsTableComponent } from './components/callers-stats-table/callers-stats-table.component';
import { EsFinanceDoubleRowComponent } from './components/es-rows/es-finance-double-row/es-finance-double-row.component';
import { EsOverviewDoubleRowComponent } from './components/es-rows/es-overview-double-row/es-overview-double-row.component';
import { EsAdsViewDoubleRowComponent } from './components/es-rows/es-ads-view-double-row/es-ads-view-double-row.component';
import { EsPayStatsDoubleRowComponent } from './components/es-rows/es-pay-stats-double-row/es-pay-stats-double-row.component';
import { EsTodoDoubleRowComponent } from './components/es-rows/es-todo-double-row/es-todo-double-row.component';
import { ErrorAlertModalComponent } from './modals/error-alert-modal/error-alert-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EventSnapshotModalComponent } from './modals/event-snapshot-modal/event-snapshot-modal.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ActionButtonModalComponent } from './modals/action-button-modal/action-button-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmEventCancelComponent } from './components/confirm-event-cancel/confirm-event-cancel.component';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    AdminEventSchedulePageComponent,
    AdminSubscribersPageComponent,
    EventScheduleCreatePageComponent,
    EventScheduleEditformComponent,
    SubscriberEditformComponent,
    SubscriberCreatePageComponent,
    FunnelEditformComponent,
    AdminFunnelPageComponent,
    FunnelCreatePageComponent,
    AdminTopicsPageComponent,
    TopicsCreatePageComponent,
    TopicsEditformComponent,
    AdminAdsViewEventScheduleComponent,
    AdminPayStatsEventScheduleComponent,
    AdminToDoEventScheduleComponent,
    EventStatusComponent,
    EventPopUpComponent,
    EventAdminTabsComponent,
    EventSchedulesPageComponent,
    ChangeStatusModalComponent,
    UpcomingCallsComponent,
    PopUpIframeComponent,
    AdminEventInfosPageComponent,
    EventInfosCreatePageComponent,
    EventInfosEditformComponent,
    ChangePayStatsPopUpComponent,
    StatisticsPageComponent,
    StatisticsGridComponent,
    ClientEventSchedulesComponent,
    EventScheduleClientCreatePageComponent,
    ClientEventScheduleEditFormComponent,
    EventSchedulesTitleComponent,
    DraftsPageComponent,
    ClientMonthTableComponent,
    ClientDraftsMonthTableComponent,
    AdminSubmitsPageComponent,
    SubmitsMonthTableComponent,
    FinancialsEsComponent,
    BackendTeamsComponent,
    BackendPageComponent,
    ActivityLogModalComponent,
    AeStatsComponent,
    AeStatsPageComponent,
    AeStatsSumUpComponent,
    AssignCallsPageComponent,
    AssignCallsTableComponent,
    ChangeAssignerPopupComponent,
    VenueSpecificNotesComponent,
    ReferralSourcesPageComponent,
    ReferralSourcesCreatePageComponent,
    ReferralSourcesEditformComponent,
    CallerStatsPageComponent,
    CallersStatsTableComponent,
    EsFinanceDoubleRowComponent,
    EsOverviewDoubleRowComponent,
    EsAdsViewDoubleRowComponent,
    EsPayStatsDoubleRowComponent,
    EsTodoDoubleRowComponent,
    EventSnapshotModalComponent,
    ActionButtonModalComponent,
    ConfirmEventCancelComponent,
  ],
  imports: [
    ErrorAlertModalComponent,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    GooglePlaceModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    InfiniteScrollModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyArK0PM_qJ07Bp3pBZ1ER2wlUNRInZ_eQc',
    }),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    ModalModule,
  ],
  exports: [UpcomingCallsComponent],
})
export class EventScheduleModule {}
