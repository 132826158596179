<div
  class="modal fade"
  bsModal
  id="deleteModal"
  [config]="{ show: true }"
  tabindex="-1"
  #deleteModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0 br-16">
      <div class="modal-header">
        <h6 class="modal-title" id="deleteModalLabel">Confirm your action</h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating">
          <p class="mb-1" i18n="Confirm delete message start">
            You are about to cancel the event
          </p>
          <div class="mb-0 mt-2 d-flex align-items-center">
            <input
              class="check form-check-input m-0 me-2"
              style="cursor: pointer"
              type="checkbox"
              [(ngModel)]="confirmationCheck"
            />
            <b style="line-height: 1">I confirm this action.</b>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          [disabled]="!confirmationCheck"
          class="btn btn-wfrBlue me-2"
          (click)="confirm.emit()"
        >
          Confirm
        </button>
        <button class="btn btn-d" (click)="cancel.emit()">Discard</button>
      </div>
    </div>
  </div>
</div>
