<div
  style="width: 100% !important"
  class="d-flex justify-content-end closemenu"
  i18n="Close menu label"
>
  Close Menu
  <div class="closeIcon text-white" (click)="menuclose()">
    <i class="bi bi-x"></i>
  </div>
</div>
<div class="sidebar bg-dark d-flex flex-column sidebartext">
  <div class="d-flex flex-column">
    <div class="d-flex col mt-3 pb-4">
      <app-logo></app-logo>
    </div>
    <div class="d-flex flex-column">
      <div class="col-12">
        <ul
          class="nav nav-pills"
          (click)="reloadPendingEventsInfo(); reloadTicketsInfo()"
        >
          <!--
       #tickets_system
        reloadTicketsInfo()" -->

          <span
            *ngIf="!(repo.isClientUser$ | async) && (repo.isAnyAdmin$ | async)"
          >
            <li
              class="nav-item ac-sidemenu"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#tasksCollapse'"
              aria-expanded="false"
              aria-controls="'flush-collapse'"
              [attr.id]="'tasksCollapseHeader'"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 19.9125V14.5125C13 13.9524 13 13.6724 12.891 13.4585C12.7951 13.2703 12.6422 13.1173 12.454 13.0215C12.2401 12.9125 11.9601 12.9125 11.4 12.9125H8.6C8.03995 12.9125 7.75992 12.9125 7.54601 13.0215C7.35785 13.1173 7.20487 13.2703 7.10899 13.4585C7 13.6724 7 13.9524 7 14.5125V19.9125M1 5.91248C1 7.56933 2.34315 8.91248 4 8.91248C5.65685 8.91248 7 7.56933 7 5.91248C7 7.56933 8.34315 8.91248 10 8.91248C11.6569 8.91248 13 7.56933 13 5.91248C13 7.56933 14.3431 8.91248 16 8.91248C17.6569 8.91248 19 7.56933 19 5.91248M4.2 19.9125H15.8C16.9201 19.9125 17.4802 19.9125 17.908 19.6945C18.2843 19.5027 18.5903 19.1968 18.782 18.8205C19 18.3926 19 17.8326 19 16.7125V5.11248C19 3.99237 19 3.43232 18.782 3.00449C18.5903 2.62817 18.2843 2.32221 17.908 2.13046C17.4802 1.91248 16.9201 1.91248 15.8 1.91248H4.2C3.0799 1.91248 2.51984 1.91248 2.09202 2.13046C1.71569 2.32221 1.40973 2.62817 1.21799 3.00449C1 3.43232 1 3.99237 1 5.11248V16.7125C1 17.8326 1 18.3926 1.21799 18.8205C1.40973 19.1968 1.71569 19.5027 2.09202 19.6945C2.51984 19.9125 3.07989 19.9125 4.2 19.9125Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              Tasks
            </li>
            <div
              id="tasksCollapse"
              class="accordion-collapse collapse"
              [class.show]="
                routerUrlIncludesAny('home', 'backend', 'assigncalls')
              "
              data-bs-parent="#tasksCollapseHeader"
            >
              <div class="accordion-body py-0 expandedBlock">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/home"
                    routerLinkActive="active"
                    (click)="menuclose()"
                  >
                    <div class="col ps-2">Callers</div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    routerLink="/assigncalls"
                    routerLinkActive="active"
                    (click)="menuclose()"
                  >
                    <div class="col ps-2">Assign calls</div>
                  </a>
                </li>

                <li
                  class="nav-item"
                  *ngIf="
                    (repo.isTenantAdmin$ | async) &&
                    (repo.hasFeature$(TenantFeatures.Maps) | async)
                  "
                >
                  <a
                    class="nav-link"
                    routerLink="/backend"
                    routerLinkActive="active"
                    (click)="menuclose()"
                  >
                    <div class="col ps-2">Backend</div>
                  </a>
                </li>
              </div>
            </div>
          </span>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              !(repo.isAdvisorsExcel$ | async) &&
              features.news &&
              (repo.hasFeature$(TenantFeatures.News) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/posts"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.60802 8.4375H10.608M6.60802 11.9375H13.608M11.108 18.9375C15.8024 18.9375 19.608 15.1319 19.608 10.4375C19.608 5.74308 15.8024 1.9375 11.108 1.9375C6.4136 1.9375 2.60802 5.74308 2.60802 10.4375C2.60802 11.3875 2.76387 12.3011 3.05139 13.1541C3.15959 13.4751 3.21369 13.6356 3.22345 13.7589C3.23309 13.8807 3.2258 13.9661 3.19568 14.0844C3.16517 14.2043 3.09782 14.329 2.96312 14.5783L1.32746 17.6059C1.09415 18.0377 0.977493 18.2536 1.0036 18.4203C1.02634 18.5654 1.11177 18.6932 1.2372 18.7697C1.3812 18.8576 1.62532 18.8323 2.11356 18.7819L7.23459 18.2525C7.38967 18.2365 7.46721 18.2285 7.53788 18.2312C7.6074 18.2338 7.65647 18.2404 7.72426 18.256C7.79318 18.2719 7.87984 18.3053 8.05318 18.372C9.00123 18.7373 10.0312 18.9375 11.108 18.9375Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for news page">News</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              features.calendar &&
              (repo.hasFeature$(TenantFeatures.Calendar) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/events"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for calendar page">Calendar</div>
            </a>
          </li>
          <span
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              (repo.hasFeature$(TenantFeatures.Documents) | async)
            "
          >
            <li
              *ngFor="let doctype of filterShown(doctypesRepo.all$ | async)"
              class="nav-item"
            >
              <a
                class="nav-link"
                routerLink="/documents/{{ doctype.id }}"
                routerLinkActive="active"
                (click)="menuclose()"
              >
                <div class="avatar avatar-40 rounded">
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 6V5.8C17 4.11984 17 3.27976 16.673 2.63803C16.3854 2.07354 15.9265 1.6146 15.362 1.32698C14.7202 1 13.8802 1 12.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H9.5M9.5 10H5M8.5 14H5M13 6H5M15 17V11.5C15 10.6716 15.6716 10 16.5 10C17.3284 10 18 10.6716 18 11.5V17C18 18.6569 16.6569 20 15 20C13.3431 20 12 18.6569 12 17V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="col" i18n="Label for news page">
                  {{ doctype.name.slice(0, 25)
                  }}<span *ngIf="doctype.name.length > 25">...</span>
                </div>
              </a>
            </li>
          </span>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              features.timelogs &&
              (repo.hasFeature$(TenantFeatures.TimeLogs) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/timelogs"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 8.8125V12.8125L12.5 14.3125M10 4.3125C5.30558 4.3125 1.5 8.11808 1.5 12.8125C1.5 17.5069 5.30558 21.3125 10 21.3125C14.6944 21.3125 18.5 17.5069 18.5 12.8125C18.5 8.11808 14.6944 4.3125 10 4.3125ZM10 4.3125V1.3125M8 1.3125H12M18.329 4.90454L16.829 3.40454L17.579 4.15454M1.67102 4.90454L3.17102 3.40454L2.42102 4.15454"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for time logs page">Time Logs</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              features.mwevents &&
              (repo.hasFeature$(TenantFeatures.Collaborations) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/mwevents"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9999 1.5625L2.09332 12.2504C1.74451 12.6689 1.57011 12.8782 1.56744 13.055C1.56512 13.2086 1.63359 13.3548 1.75312 13.4514C1.89061 13.5625 2.16304 13.5625 2.7079 13.5625H9.99986L8.99986 21.5625L17.9064 10.8746C18.2552 10.4561 18.4296 10.2468 18.4323 10.07C18.4346 9.91638 18.3661 9.7702 18.2466 9.67361C18.1091 9.5625 17.8367 9.5625 17.2918 9.5625H9.99986L10.9999 1.5625Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for events page">Collaborations</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              features.whistleblow &&
              (repo.hasFeature$(TenantFeatures.Whistleblow) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/whistleblow"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 7.02084V11.0208M10 15.0208H10.01M1 6.96228V15.0794C1 15.422 1 15.5934 1.05048 15.7462C1.09515 15.8813 1.16816 16.0054 1.26463 16.1101C1.37369 16.2284 1.52345 16.3116 1.82297 16.478L9.22297 20.5892C9.50658 20.7467 9.64838 20.8255 9.79855 20.8564C9.93146 20.8837 10.0685 20.8837 10.2015 20.8564C10.3516 20.8255 10.4934 20.7467 10.777 20.5892L18.177 16.478C18.4766 16.3116 18.6263 16.2284 18.7354 16.1101C18.8318 16.0054 18.9049 15.8813 18.9495 15.7462C19 15.5934 19 15.422 19 15.0794V6.96228C19 6.61964 19 6.44832 18.9495 6.29552C18.9049 6.16034 18.8318 6.03626 18.7354 5.93157C18.6263 5.81323 18.4766 5.73003 18.177 5.56363L10.777 1.45252C10.4934 1.29496 10.3516 1.21618 10.2015 1.1853C10.0685 1.15796 9.93146 1.15796 9.79855 1.1853C9.64838 1.21618 9.50658 1.29496 9.22297 1.45252L1.82297 5.56363C1.52345 5.73003 1.37369 5.81323 1.26463 5.93157C1.16816 6.03626 1.09515 6.16034 1.05048 6.29552C1 6.44832 1 6.61964 1 6.96228Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for whistleblows page">
                Whistleblow
              </div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              features.mail &&
              (repo.hasFeature$(TenantFeatures.Messages) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/messages"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="21"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 9H4.88197C5.56717 9 6.19357 9.38713 6.5 10C6.80643 10.6129 7.43283 11 8.11803 11H13.882C14.5672 11 15.1936 10.6129 15.5 10C15.8064 9.38713 16.4328 9 17.118 9H20.5M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for mails page">Messages</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/maps"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  fill="currentColor"
                  class="bi bi-globe-central-south-asia"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM4.882 1.731a.482.482 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.721.721 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a.996.996 0 0 0-.462-.04 7.03 7.03 0 0 1 2.45-2.027Zm-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.78.78 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.454.454 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282a.61.61 0 0 0 .04-.001 7.003 7.003 0 0 1-12.658.905Z"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for calendar page">Map</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              ((repo.isUser$ | async) || (repo.isTenantAdmin$ | async)) &&
              features.apv &&
              (repo.hasFeature$(TenantFeatures.Apv) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/apv"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 11L10 13L14.5 8.5M16.9012 3.99851C17.1071 4.49653 17.5024 4.8924 18.0001 5.09907L19.7452 5.82198C20.2433 6.02828 20.639 6.42399 20.8453 6.92206C21.0516 7.42012 21.0516 7.97974 20.8453 8.47781L20.1229 10.2218C19.9165 10.7201 19.9162 11.2803 20.1236 11.7783L20.8447 13.5218C20.9469 13.7685 20.9996 14.0329 20.9996 14.2999C20.9997 14.567 20.9471 14.8314 20.8449 15.0781C20.7427 15.3249 20.5929 15.549 20.4041 15.7378C20.2152 15.9266 19.991 16.0764 19.7443 16.1785L18.0004 16.9009C17.5023 17.1068 17.1065 17.5021 16.8998 17.9998L16.1769 19.745C15.9706 20.2431 15.575 20.6388 15.0769 20.8451C14.5789 21.0514 14.0193 21.0514 13.5212 20.8451L11.7773 20.1227C11.2792 19.9169 10.7198 19.9173 10.2221 20.1239L8.47689 20.8458C7.97912 21.0516 7.42001 21.0514 6.92237 20.8453C6.42473 20.6391 6.02925 20.2439 5.82281 19.7464L5.09972 18.0006C4.8938 17.5026 4.49854 17.1067 4.00085 16.9L2.25566 16.1771C1.75783 15.9709 1.36226 15.5754 1.15588 15.0777C0.949508 14.5799 0.949228 14.0205 1.1551 13.5225L1.87746 11.7786C2.08325 11.2805 2.08283 10.7211 1.8763 10.2233L1.15497 8.47678C1.0527 8.2301 1.00004 7.96568 1 7.69863C0.999957 7.43159 1.05253 7.16715 1.15472 6.92043C1.25691 6.67372 1.40671 6.44955 1.59557 6.26075C1.78442 6.07195 2.00862 5.92222 2.25537 5.8201L3.9993 5.09772C4.49687 4.89197 4.89248 4.4972 5.0993 4.00006L5.82218 2.25481C6.02848 1.75674 6.42418 1.36103 6.92222 1.15473C7.42027 0.948424 7.97987 0.948424 8.47792 1.15473L10.2218 1.87712C10.7199 2.08291 11.2793 2.08249 11.7771 1.87595L13.523 1.15585C14.021 0.94966 14.5804 0.949702 15.0784 1.15597C15.5763 1.36223 15.972 1.75783 16.1783 2.25576L16.9014 4.00153L16.9012 3.99851Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for apvs page">APV</div>
            </a>
          </li>

          <li class="nav-item pt-5" *ngIf="repo.isAnyAdmin$ | async">
            Admin Panel
          </li>
          <li class="nav-item" *ngIf="repo.isSuperAdmin$ | async">
            <a
              class="nav-link"
              routerLink="/tenants"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <i class="bi bi-building"></i>
              </div>
              <div class="col" i18n="Label for tenants page">Tenants</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              features.apv &&
              (repo.hasFeature$(TenantFeatures.Apv) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/apv"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 11L10 13L14.5 8.5M16.9012 3.99851C17.1071 4.49653 17.5024 4.8924 18.0001 5.09907L19.7452 5.82198C20.2433 6.02828 20.639 6.42399 20.8453 6.92206C21.0516 7.42012 21.0516 7.97974 20.8453 8.47781L20.1229 10.2218C19.9165 10.7201 19.9162 11.2803 20.1236 11.7783L20.8447 13.5218C20.9469 13.7685 20.9996 14.0329 20.9996 14.2999C20.9997 14.567 20.9471 14.8314 20.8449 15.0781C20.7427 15.3249 20.5929 15.549 20.4041 15.7378C20.2152 15.9266 19.991 16.0764 19.7443 16.1785L18.0004 16.9009C17.5023 17.1068 17.1065 17.5021 16.8998 17.9998L16.1769 19.745C15.9706 20.2431 15.575 20.6388 15.0769 20.8451C14.5789 21.0514 14.0193 21.0514 13.5212 20.8451L11.7773 20.1227C11.2792 19.9169 10.7198 19.9173 10.2221 20.1239L8.47689 20.8458C7.97912 21.0516 7.42001 21.0514 6.92237 20.8453C6.42473 20.6391 6.02925 20.2439 5.82281 19.7464L5.09972 18.0006C4.8938 17.5026 4.49854 17.1067 4.00085 16.9L2.25566 16.1771C1.75783 15.9709 1.36226 15.5754 1.15588 15.0777C0.949508 14.5799 0.949228 14.0205 1.1551 13.5225L1.87746 11.7786C2.08325 11.2805 2.08283 10.7211 1.8763 10.2233L1.15497 8.47678C1.0527 8.2301 1.00004 7.96568 1 7.69863C0.999957 7.43159 1.05253 7.16715 1.15472 6.92043C1.25691 6.67372 1.40671 6.44955 1.59557 6.26075C1.78442 6.07195 2.00862 5.92222 2.25537 5.8201L3.9993 5.09772C4.49687 4.89197 4.89248 4.4972 5.0993 4.00006L5.82218 2.25481C6.02848 1.75674 6.42418 1.36103 6.92222 1.15473C7.42027 0.948424 7.97987 0.948424 8.47792 1.15473L10.2218 1.87712C10.7199 2.08291 11.2793 2.08249 11.7771 1.87595L13.523 1.15585C14.021 0.94966 14.5804 0.949702 15.0784 1.15597C15.5763 1.36223 15.972 1.75783 16.1783 2.25576L16.9014 4.00153L16.9012 3.99851Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for apvs page">APV</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              features.apv &&
              (repo.hasFeature$(TenantFeatures.Apv) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/apv-answers"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 11L10 13L14.5 8.5M16.9012 3.99851C17.1071 4.49653 17.5024 4.8924 18.0001 5.09907L19.7452 5.82198C20.2433 6.02828 20.639 6.42399 20.8453 6.92206C21.0516 7.42012 21.0516 7.97974 20.8453 8.47781L20.1229 10.2218C19.9165 10.7201 19.9162 11.2803 20.1236 11.7783L20.8447 13.5218C20.9469 13.7685 20.9996 14.0329 20.9996 14.2999C20.9997 14.567 20.9471 14.8314 20.8449 15.0781C20.7427 15.3249 20.5929 15.549 20.4041 15.7378C20.2152 15.9266 19.991 16.0764 19.7443 16.1785L18.0004 16.9009C17.5023 17.1068 17.1065 17.5021 16.8998 17.9998L16.1769 19.745C15.9706 20.2431 15.575 20.6388 15.0769 20.8451C14.5789 21.0514 14.0193 21.0514 13.5212 20.8451L11.7773 20.1227C11.2792 19.9169 10.7198 19.9173 10.2221 20.1239L8.47689 20.8458C7.97912 21.0516 7.42001 21.0514 6.92237 20.8453C6.42473 20.6391 6.02925 20.2439 5.82281 19.7464L5.09972 18.0006C4.8938 17.5026 4.49854 17.1067 4.00085 16.9L2.25566 16.1771C1.75783 15.9709 1.36226 15.5754 1.15588 15.0777C0.949508 14.5799 0.949228 14.0205 1.1551 13.5225L1.87746 11.7786C2.08325 11.2805 2.08283 10.7211 1.8763 10.2233L1.15497 8.47678C1.0527 8.2301 1.00004 7.96568 1 7.69863C0.999957 7.43159 1.05253 7.16715 1.15472 6.92043C1.25691 6.67372 1.40671 6.44955 1.59557 6.26075C1.78442 6.07195 2.00862 5.92222 2.25537 5.8201L3.9993 5.09772C4.49687 4.89197 4.89248 4.4972 5.0993 4.00006L5.82218 2.25481C6.02848 1.75674 6.42418 1.36103 6.92222 1.15473C7.42027 0.948424 7.97987 0.948424 8.47792 1.15473L10.2218 1.87712C10.7199 2.08291 11.2793 2.08249 11.7771 1.87595L13.523 1.15585C14.021 0.94966 14.5804 0.949702 15.0784 1.15597C15.5763 1.36223 15.972 1.75783 16.1783 2.25576L16.9014 4.00153L16.9012 3.99851Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for apv answers page">
                APV Answers
              </div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              (repo.hasFeature$(TenantFeatures.Documents) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/documents"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 19 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 6V5.8C17 4.11984 17 3.27976 16.673 2.63803C16.3854 2.07354 15.9265 1.6146 15.362 1.32698C14.7202 1 13.8802 1 12.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H9.5M9.5 10H5M8.5 14H5M13 6H5M15 17V11.5C15 10.6716 15.6716 10 16.5 10C17.3284 10 18 10.6716 18 11.5V17C18 18.6569 16.6569 20 15 20C13.3431 20 12 18.6569 12 17V13"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for news page">Documents</div>
            </a>
          </li>

          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              features.news &&
              (repo.hasFeature$(TenantFeatures.News) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/posts"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.60802 8.4375H10.608M6.60802 11.9375H13.608M11.108 18.9375C15.8024 18.9375 19.608 15.1319 19.608 10.4375C19.608 5.74308 15.8024 1.9375 11.108 1.9375C6.4136 1.9375 2.60802 5.74308 2.60802 10.4375C2.60802 11.3875 2.76387 12.3011 3.05139 13.1541C3.15959 13.4751 3.21369 13.6356 3.22345 13.7589C3.23309 13.8807 3.2258 13.9661 3.19568 14.0844C3.16517 14.2043 3.09782 14.329 2.96312 14.5783L1.32746 17.6059C1.09415 18.0377 0.977493 18.2536 1.0036 18.4203C1.02634 18.5654 1.11177 18.6932 1.2372 18.7697C1.3812 18.8576 1.62532 18.8323 2.11356 18.7819L7.23459 18.2525C7.38967 18.2365 7.46721 18.2285 7.53788 18.2312C7.6074 18.2338 7.65647 18.2404 7.72426 18.256C7.79318 18.2719 7.87984 18.3053 8.05318 18.372C9.00123 18.7373 10.0312 18.9375 11.108 18.9375Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for news page">News</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              features.calendar &&
              (repo.hasFeature$(TenantFeatures.Calendar) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/events"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for calendar page">Calendar</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              (repo.hasFeature$(TenantFeatures.TimeLogs) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/timelogs"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 8.8125V12.8125L12.5 14.3125M10 4.3125C5.30558 4.3125 1.5 8.11808 1.5 12.8125C1.5 17.5069 5.30558 21.3125 10 21.3125C14.6944 21.3125 18.5 17.5069 18.5 12.8125C18.5 8.11808 14.6944 4.3125 10 4.3125ZM10 4.3125V1.3125M8 1.3125H12M18.329 4.90454L16.829 3.40454L17.579 4.15454M1.67102 4.90454L3.17102 3.40454L2.42102 4.15454"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="">Time Logs</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              (repo.hasFeature$(TenantFeatures.TimeLogs) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="admin/overview/timelogs"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 8.8125V12.8125L12.5 14.3125M10 4.3125C5.30558 4.3125 1.5 8.11808 1.5 12.8125C1.5 17.5069 5.30558 21.3125 10 21.3125C14.6944 21.3125 18.5 17.5069 18.5 12.8125C18.5 8.11808 14.6944 4.3125 10 4.3125ZM10 4.3125V1.3125M8 1.3125H12M18.329 4.90454L16.829 3.40454L17.579 4.15454M1.67102 4.90454L3.17102 3.40454L2.42102 4.15454"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="">Time Logs Overview</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              features.mwevents &&
              (repo.hasFeature$(TenantFeatures.Collaborations) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/mwevents"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9999 1.5625L2.09332 12.2504C1.74451 12.6689 1.57011 12.8782 1.56744 13.055C1.56512 13.2086 1.63359 13.3548 1.75312 13.4514C1.89061 13.5625 2.16304 13.5625 2.7079 13.5625H9.99986L8.99986 21.5625L17.9064 10.8746C18.2552 10.4561 18.4296 10.2468 18.4323 10.07C18.4346 9.91638 18.3661 9.7702 18.2466 9.67361C18.1091 9.5625 17.8367 9.5625 17.2918 9.5625H9.99986L10.9999 1.5625Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for news page">Collaborations</div>
            </a>
          </li>

          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              features.whistleblow &&
              (repo.hasFeature$(TenantFeatures.Whistleblow) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/whistleblow"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 7.02084V11.0208M10 15.0208H10.01M1 6.96228V15.0794C1 15.422 1 15.5934 1.05048 15.7462C1.09515 15.8813 1.16816 16.0054 1.26463 16.1101C1.37369 16.2284 1.52345 16.3116 1.82297 16.478L9.22297 20.5892C9.50658 20.7467 9.64838 20.8255 9.79855 20.8564C9.93146 20.8837 10.0685 20.8837 10.2015 20.8564C10.3516 20.8255 10.4934 20.7467 10.777 20.5892L18.177 16.478C18.4766 16.3116 18.6263 16.2284 18.7354 16.1101C18.8318 16.0054 18.9049 15.8813 18.9495 15.7462C19 15.5934 19 15.422 19 15.0794V6.96228C19 6.61964 19 6.44832 18.9495 6.29552C18.9049 6.16034 18.8318 6.03626 18.7354 5.93157C18.6263 5.81323 18.4766 5.73003 18.177 5.56363L10.777 1.45252C10.4934 1.29496 10.3516 1.21618 10.2015 1.1853C10.0685 1.15796 9.93146 1.15796 9.79855 1.1853C9.64838 1.21618 9.50658 1.29496 9.22297 1.45252L1.82297 5.56363C1.52345 5.73003 1.37369 5.81323 1.26463 5.93157C1.16816 6.03626 1.09515 6.16034 1.05048 6.29552C1 6.44832 1 6.61964 1 6.96228Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for whistleblow page">
                Whistleblow
              </div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              features.mail &&
              (repo.hasFeature$(TenantFeatures.Messages) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/messages"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 9H4.88197C5.56717 9 6.19357 9.38713 6.5 10C6.80643 10.6129 7.43283 11 8.11803 11H13.882C14.5672 11 15.1936 10.6129 15.5 10C15.8064 9.38713 16.4328 9 17.118 9H20.5M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col" i18n="Label for mail page">Messages</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="/admin/clients"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  fill="currentColor"
                  class="bi bi-globe-central-south-asia"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM4.882 1.731a.482.482 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.721.721 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a.996.996 0 0 0-.462-.04 7.03 7.03 0 0 1 2.45-2.027Zm-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.78.78 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.454.454 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282a.61.61 0 0 0 .04-.001 7.003 7.003 0 0 1-12.658.905Z"
                  />
                </svg>
              </div>
              <div class="col">Clients</div>
            </a>
          </li>

          <li
            class="nav-item ac-sidemenu"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#prebuiltCollapse'"
            aria-expanded="false"
            aria-controls="'flush-collapse'"
            [attr.id]="'prebuiltCollapseHeader'"
            *ngIf="
              !(repo.isClientUser$ | async) &&
              (repo.isTenantAdmin$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <div class="avatar avatar-40 rounded">
              <svg
                width="18"
                height="19"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 5V15M14 5V15M14 10L6 10M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Prebuilt
          </li>
          <div
            id="prebuiltCollapse"
            class="accordion-collapse collapse"
            [class.show]="
              routerUrlIncludesAny('topics', 'event-infos', 'referralSources')
            "
            data-bs-parent="#prebuiltCollapseHeader"
          >
            <div class="accordion-body py-0 expandedBlock">
              <li
                class="nav-item"
                *ngIf="
                  (repo.isTenantAdmin$ | async) &&
                  (repo.hasFeature$(TenantFeatures.Maps) | async)
                "
              >
                <a
                  class="nav-link"
                  routerLink="/admin/event-infos"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Event Info</div>
                </a>
              </li>

              <li
                class="nav-item"
                *ngIf="
                  (repo.isTenantAdmin$ | async) &&
                  (repo.hasFeature$(TenantFeatures.Maps) | async)
                "
              >
                <a
                  class="nav-link"
                  routerLink="/admin/topics"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Topics</div>
                </a>
              </li>
              <li
                class="nav-item"
                *ngIf="
                  (repo.isTenantAdmin$ | async) &&
                  (repo.hasFeature$(TenantFeatures.Maps) | async)
                "
              >
                <a
                  class="nav-link"
                  routerLink="/admin/referralSources"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Referral Sources</div>
                </a>
              </li>
            </div>
          </div>

          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="eventSchedules"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <!--<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                </svg>-->
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col">Event Schedule</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              !(repo.isTenantAdmin$ | async) &&
              (repo.isClientUser$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="client/eventSchedules"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <!--<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
              </svg>-->
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col">Event Schedule</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              !(repo.isTenantAdmin$ | async) &&
              (repo.isClientUser$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="client/eventSchedules/new"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 8H3M16 2V5M8 2V5M12 18V12M9 15H15M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col">Add Event</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              !(repo.isTenantAdmin$ | async) &&
              (repo.isClientUser$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="client/drafts"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 11.5V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22H12.5M21 10H3M16 2V6M8 2V6M18 21V15M15 18H21"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col">Drafts</div>
            </a>
          </li>
          <li
            class="nav-item"
            *ngIf="
              (repo.isTenantAdmin$ | async) &&
              !(repo.isClientUser$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLink="client/pending"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 10H3M16 2V6M8 2V6M9 16L11 18L15.5 13.5M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col position-relative">
                Pending Events
                <span
                  *ngIf="esRepo.pendingEventsCountSubject | async"
                  class="position-absolute top-0 translate-middle pe-2 badge rounded-pill bg-danger"
                  style="left: calc(100% - 5px)"
                >
                  {{ esRepo.pendingEventsCountSubject | async }}
                </span>
              </div>
            </a>
          </li>

          <li
            class="nav-item ac-sidemenu"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#statsCollapse'"
            aria-expanded="false"
            aria-controls="'flush-collapse'"
            [attr.id]="'statsCollapseHeader'"
            *ngIf="
              (repo.isAnyAdmin$ | async) || (repo.isAdvisorsExcel$ | async)
            "
          >
            <div class="avatar avatar-40 rounded">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 22L20.5 20.5M22 10H2M22 12V8.2C22 7.0799 22 6.51984 21.782 6.09202C21.5903 5.7157 21.2843 5.40974 20.908 5.21799C20.4802 5 19.9201 5 18.8 5H5.2C4.0799 5 3.51984 5 3.09202 5.21799C2.7157 5.40973 2.40973 5.71569 2.21799 6.09202C2 6.51984 2 7.0799 2 8.2V15.8C2 16.9201 2 17.4802 2.21799 17.908C2.40973 18.2843 2.71569 18.5903 3.09202 18.782C3.51984 19 4.0799 19 5.2 19H10.5M21.5 18C21.5 19.933 19.933 21.5 18 21.5C16.067 21.5 14.5 19.933 14.5 18C14.5 16.067 16.067 14.5 18 14.5C19.933 14.5 21.5 16.067 21.5 18Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Statistics
          </li>
          <div
            id="statsCollapse"
            class="accordion-collapse collapse"
            [class.show]="router.url.includes('statistics')"
            data-bs-parent="#statsCollapseHeader"
          >
            <div class="accordion-body py-0 expandedBlock">
              <li class="nav-item" *ngIf="repo.isAdvisorsExcel$ | async">
                <a
                  class="nav-link"
                  routerLink="/aestatistics"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">AE Stats</div>
                </a>
              </li>

              <li class="nav-item" *ngIf="repo.isAnyAdmin$ | async">
                <a
                  class="nav-link"
                  routerLink="/statistics"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">WFR Stats</div>
                </a>
              </li>
              <li class="nav-item" *ngIf="repo.isAnyAdmin$ | async">
                <a
                  class="nav-link"
                  routerLink="/callerstatistics"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Caller Stats</div>
                </a>
              </li>
            </div>
          </div>
          <!-- //#tickets_system  -->
          <li
            class="nav-item ac-sidemenu position-relative"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#ticketsCollapse'"
            aria-expanded="false"
            aria-controls="'flush-collapse'"
            [attr.id]="'ticketsHeader'"
            *ngIf="
              (repo.isAnyAdmin$ | async) &&
              (repo.hasFeature$(TenantFeatures.Tickets) | async)
            "
          >
            <div class="avatar avatar-40 rounded">
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 12H7.51M12 12H12.01M16.5 12H16.51M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.1971 3.23374 14.3397 3.65806 15.3845C3.73927 15.5845 3.77988 15.6845 3.798 15.7653C3.81572 15.8443 3.8222 15.9028 3.82221 15.9839C3.82222 16.0667 3.80718 16.1569 3.77711 16.3374L3.18413 19.8952C3.12203 20.2678 3.09098 20.4541 3.14876 20.5888C3.19933 20.7067 3.29328 20.8007 3.41118 20.8512C3.54589 20.909 3.73218 20.878 4.10476 20.8159L7.66265 20.2229C7.84309 20.1928 7.9333 20.1778 8.01613 20.1778C8.09715 20.1778 8.15566 20.1843 8.23472 20.202C8.31554 20.2201 8.41552 20.2607 8.61549 20.3419C9.6603 20.7663 10.8029 21 12 21ZM8 12C8 12.2761 7.77614 12.5 7.5 12.5C7.22386 12.5 7 12.2761 7 12C7 11.7239 7.22386 11.5 7.5 11.5C7.77614 11.5 8 11.7239 8 12ZM12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12ZM17 12C17 12.2761 16.7761 12.5 16.5 12.5C16.2239 12.5 16 12.2761 16 12C16 11.7239 16.2239 11.5 16.5 11.5C16.7761 11.5 17 11.7239 17 12Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            Tickets
            <span
              *ngIf="ticketsRepo.ticketsCountSubject | async"
              class="position-absolute top-0 translate-middle pe-2 badge rounded-pill bg-danger"
              style="left: calc(100% - 5px)"
            >
              {{ ticketsRepo.ticketsCountSubject | async }}
            </span>
          </li>
          <div
            id="ticketsCollapse"
            class="accordion-collapse collapse"
            [class.show]="router.url.includes('ticket')"
            data-bs-parent="#ticketsHeader"
          >
            <div class="accordion-body py-0 expandedBlock">
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/tickets/opened"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Open</div>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/tickets/inProgress"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">In Progress</div>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/tickets/closed"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Closed</div>
                </a>
              </li>
            </div>
          </div>

          <li
            class="nav-item"
            *ngIf="
              (repo.folder$ | async) &&
              (repo.isClientUser$ | async) &&
              (repo.hasFeature$(TenantFeatures.Maps) | async)
            "
          >
            <a
              class="nav-link"
              routerLinkActive="active"
              (click)="menuclose(); openSpreadSheetInNewTab()"
            >
              <div class="avatar avatar-40 rounded">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="col">My Folder</div>
            </a>
          </li>

          <li
            class="nav-item ac-sidemenu"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#teamUserCollapse'"
            aria-expanded="false"
            aria-controls="'flush-collapse'"
            [attr.id]="'teamUserCollapseHeader'"
            *ngIf="!(repo.isClientUser$ | async) && (repo.isAnyAdmin$ | async)"
          >
            <div class="avatar avatar-40 rounded">
              <i class="bi bi-people"></i>
            </div>
            Teams/Users
          </li>
          <div
            id="teamUserCollapse"
            class="accordion-collapse collapse"
            [class.show]="routerUrlIncludesAny('teams', 'users')"
            data-bs-parent="#teamUserCollapseHeader"
          >
            <div class="accordion-body py-0 expandedBlock">
              <li
                class="nav-item"
                *ngIf="
                  (repo.isTenantAdmin$ | async) &&
                  (repo.hasFeature$(TenantFeatures.Maps) | async)
                "
              >
                <a
                  class="nav-link"
                  routerLink="/admin/teams"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Teams</div>
                </a>
              </li>
              <li class="nav-item" *ngIf="repo.isAnyAdmin$ | async">
                <a
                  class="nav-link"
                  routerLink="/users"
                  routerLinkActive="active"
                  (click)="menuclose()"
                >
                  <div class="col ps-2">Users</div>
                </a>
              </li>
            </div>
          </div>
          <li
            class="nav-item w-100"
            *ngIf="
              (repo.isClientUser$ | async) &&
              clientsOptions &&
              clientsOptions.length > 1
            "
          >
            Active Client
            <app-datalist-select
              [clearable]="false"
              [options]="clientsOptions"
              [multiple]="false"
              [activeValue]="repo.activeClientId$ | async"
              (activeValueChange)="changeActiveClient($event)"
            ></app-datalist-select>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="d-flex col">
    <app-menuuser-card
      class="col-12 d-flex align-items-end"
    ></app-menuuser-card>
  </div>
</div>
