import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { filter } from 'lodash';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
  deleteAllEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { sortBy, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { IDocumentType } from './documenttype.repository';
import { IFolder } from './folders.repository';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';
import { ISubscriber } from './subscribers.repository';
import { ICompanyMarker } from './companies.repository';
import { IComments } from './posts.repository';
import _ from 'lodash';
import { IEventInfo } from './event-infos.repository';
import { User } from './users.repository';
import { ExceptClient, IAESummary } from './quaters.repository';

export const StatusesOptions: DatalistOption[] = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Ready', value: 'Ready' },
  { label: 'ON', value: 'ON' },
  { label: 'OFF', value: 'OFF' },
  {
    label: 'Automatic',
    value: 'Automatic',
    subMenu: [
      { label: '1/4', value: '1' },
      { label: '2/4', value: '2' },
      { label: '3/4', value: '3' },
      { label: '4/4', value: '4' },
    ],
  },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Rescheduled', value: 'Rescheduled' },
];
const { state, config } = createState(
  withEntities<IMonthWithEvents>(),
  withRequestsStatus(),
  withRequestsCache()
);

const store = new Store({ name: 'posts', state, config });
const upcomingStore = new Store({ name: 'upcomingCalls', state, config });
const backendStore = new Store({ name: 'backendStore', state, config });

export const AdsOptions: DatalistOption[] = [
  { label: 'Off', value: 'Off' },
  { label: 'On', value: 'On' },
  { label: 'Ads QA Done', value: 'Ads QA Done' },
  { label: 'Canceled', value: 'Canceled' },
];

export const AtCostOptions: DatalistOption[] = [
  { label: 'No', value: 'No' },
  { label: 'C', value: 'C' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
  { label: 'C3', value: 'C3' },
];
export const MonthsOptions: DatalistOption[] = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
  { label: 'Without Date', value: 'Without Date' },
];
export const PayStatsOptions: DatalistOption[] = [
  { label: 'Yes', value: 'Yes' },
  { label: 'Yes (AE)', value: 'Yes (AE)' },
  { label: 'Sent', value: 'Sent' },
  { label: 'Sent (AE)', value: 'Sent (AE)' },
];
export const ReminderProcessOptions: DatalistOption[] = [
  {
    label: 'WFR handles all (calls, emails, texts, etc.)',
    value: 'WFR handles all (calls, emails, texts, etc.)',
  },
  {
    label:
      'Hybrid (split efforts between WFR and your team based on agreement)',
    value:
      'Hybrid (split efforts between WFR and your team based on agreement)',
  },
  {
    label:
      '100% external (WFR handles no correspondence at all - your team is in charge of all correspondence)',
    value:
      '100% external (WFR handles no correspondence at all - your team is in charge of all correspondence)',
  },
];
export interface IFunnel {
  id: string;
  tenantId: string;
  name: string;
}
export interface IActivityLog {
  id: string;
  eventScheduleId: string;
  eventSchedule?: IEventSchedule;
  userId: string;
  user?: User;
  tenantId: string;
  type: string;
  createdAt: Date;
  oldStatus: string;
  newStatus: string;
}
export interface IYear {
  id: string;
  tenantId: string;
  value: number;
}
export interface IMarkingDot {
  id: string;
  tenantId: string;
  name?: string;
  type: string;
  deletedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}
export interface IMonthWithEvents {
  id: string;
  name: string;
  date: Date;
  eventSchedules: IEventSchedule[];
  showEventSchedules?: IEventSchedule[];
  showEventsCount?: number;
  expanded: boolean;
  aeCount: number;
  eventsCount?: number;

  rescheduledCount?: number;
  cancelledCount?: number;
  netEventsCount?: number;
  // rcCount?: number;
  sortStatus?: string;
  sortStatusNum?: number;
  exportCheck: boolean;
  aeSummary?: IAESummary;
}

export interface ITopic {
  id: string;
  tenantId: string;
  name: string;
  companyId: string;
  googleSheetUrl: string;
}

export interface IRoom {
  id: string;
  tenantId: string;
  companyLocationId: string;
  name: string;
  parking: string;
  desiredBUCapasity: string;
}
export interface IEventScheduleLocation {
  id: string;
  title: string;
  locationTitle: string;
  order: number;
  address: string;
  latitude: number;
  longitude: number;
  checked: boolean;
  circleSizeLabel: string;
  circleSize: number;
  tenantId: string;
  defaultRadius: number;
  radius: number;
  companyId: string;
  lastEventDate: Date | null;
  lastEventDescription: string;
  formatedLastEventDate: string;
  fillColor?: string;
  shown: boolean;
  parking: string;
}
export interface IMonthState {
  id: string;
  pagePropertiesId: string;
  name: string;
  expanded: boolean;
  statusFilter?: string;
  statusFilterNum: number;
  exportCheck: boolean;
}
export interface IPageProperties {
  id: string;
  name: string;
  userId: string;
  filterString: string;
  cc: boolean;
  location: boolean;
  topic: boolean;
  year: number;
  monthStates: IMonthState[];
  createdAt: Date;
  updatedAt: Date;
  tenantId: string;
  tabSelected: string;
}
export interface EsAdminPage {
  months: IMonthWithEvents[];
  pageProperties: IPageProperties;
  years: number[];
}
export interface TimeZoneInfo {
  lng?: number;
  lat?: number;
  address?: string;
  timeZoneInfo?: string;
}
export interface IEventSchedule {
  id: string;
  tenantId: string;
  timeZoneInfo: string;
  clientId: string;
  eventScheduleLocationId: string;
  eventScheduleLocation: IEventScheduleLocation;
  topic: string;
  topicNotes: string;
  newTopic?: string;
  waitComplianceApproval?: boolean;
  paid: number;
  paidComment: string;
  ae: boolean;
  clientTitle: string;
  finalRegistrants: number;
  finalRegistrantsD2: number;
  registrants: number;
  registrantsD2: number;
  prebuildTopicId: number;
  funnelName: string;
  ads: string;
  prebuildTopic: ITopic;
  date: Date;
  date2: Date;
  time2: string;
  prebuildTime2Id: string;
  prebuildTime2?: IEventInfo;
  prebuildTime?: IEventInfo;

  prebuildTimeId: string;
  doubleEvent: boolean;
  asap: boolean;
  formatedDate: string;
  formatedDate2: string;
  googleSheet: string;
  googleSheetD2: string;
  sheetError?: string;
  sheetErrorD2?: string;
  attendees: number;
  attendeesD2: number;
  totalAttendees: number;
  eventScheduleLocationTitle: string;
  roomId: string;
  room: IRoom;
  roomD2Id: string;
  roomD2: IRoom;
  formatedDateAdmin: string;
  formatedDate2Admin: string;
  roomName: string;
  parking: string;
  parkingD2: string;

  time: string;
  desiredBU: string;
  desiredBUD2: string;

  notes: string;
  venueSpecificNotes?: string;

  reminderProcess: string;
  slyCast: string;
  emailBlast1st: string;
  emailBlast2nd: string;
  emailBlast1stD2: string;
  emailBlast2ndD2: string;
  fewSpots: Date;
  fewSpotsScheduled: boolean;
  fiveDayWfrCall: string;
  fiveDayWfrCallDate: Date;
  fiveDayWfrCallD2Date: Date;

  fiveDayWfrCallDone: boolean;
  emailBlastScheduled: boolean;
  vmCode: string;
  wfrReminder: string;
  showUps: string;
  showUpsRate: number;
  adSpent: string;
  adSpentNotes: string;

  comments: IComments[];
  shownSubscribers: ISubscriber[];
  subscribers: ISubscriber[];
  client: ICompanyMarker;
  funnelD2: IFunnel;

  wfrCall1: string;
  wfrCall1Date: Date;
  wfrCall1Funnel: IFunnel;
  wfrCall1FunnelId: string;
  wfrCall1Done: boolean;

  wfrCall2: string;
  wfrCall2Date: Date;
  wfrCall2Funnel: IFunnel;
  wfrCall2FunnelId: string;
  wfrCall2Done: boolean;

  wfrCall1D2: string;
  wfrCall1D2Date: Date;
  wfrCall1D2Funnel: IFunnel;
  wfrCall1D2FunnelId: string;
  wfrCall1D2Done: boolean;

  wfrCall2D2: string;
  wfrCall2D2Date: Date;
  wfrCall2D2Funnel: IFunnel;
  wfrCall2D2FunnelId: string;
  wfrCall2D2Done: boolean;

  //First Funnel row
  funnel: IFunnel;
  funnelId: string;
  funnelDone: boolean;
  funnelQAby: IFunnel;
  funnelQAbyId: string;
  funnelQADone: boolean;
  funnelAds: IFunnel;
  funnelAdsId: string;
  funnelAdsDone: boolean;
  funnelAdsQAby: IFunnel;
  funnelAdsQAbyId: string;
  funnelAdsQADone: boolean;

  //Second Funnel row
  funnelSecond: IFunnel;
  funnelSecondId: string;
  funnelSecondDone: boolean;
  funnelSecondQAby: IFunnel;
  funnelSecondQAbyId: string;
  funnelSecondQADone: boolean;
  funnelSecondAds: IFunnel;
  funnelSecondAdsId: string;
  funnelSecondAdsDone: boolean;
  funnelSecondAdsQAby: IFunnel;
  funnelSecondAdsQAbyId: string;
  funnelSecondAdsQADone: boolean;

  payStats: string;
  status: string;
  statusD2: string;

  statusNum: string;
  statusNumD2: string;

  reminder: string;
  reminderReady: boolean;

  clientTouch: string;
  clientTouchReady: boolean;
  emailBlast1Ready: boolean;
  emailBlast2Ready: boolean;
  wfrReminderReady: boolean;
  fewSpotsReady: boolean;
  locationUrl: string;
  clientUrl: string;
  landingPageUrl: string;
  landingPageUrl2: string;

  landingPageId: string;
  landingPageId2: string;
  googleSheetId: string;
  googleSheetId2: string;
  wfrDinnerOrLunch: boolean;
  twoPartSetup: boolean;
  adsGeneral: string;
  adsQAby: boolean;
  funnelD2Id: string;
  funnelD2Done: boolean;
  funnelD2QAby: string;
  funnelD2QADone: boolean;
  fewSpotsD2Scheduled: boolean;
  fiveDayWfrCallD2Done: boolean;
  fiveDayWfrCallD2: string;
  vmCodeD2: string;
  emailBlastD2Scheduled: boolean;
  fewSpotsD2: Date;
  adsD1: string;
  adsD1Done: boolean;
  adsD1QAby: string;
  adsD1QADone: string;
  adsD2: string;
  adsD2Done: boolean;
  adsD2QAby: string;
  adsD2QADone: string;
  atCost: string;
  atCostD2: string;
  orangeDot: boolean;
  pinkDot: boolean;
  orangeDotD2: boolean;
  pinkDotD2: boolean;
  isInReview?: boolean;
  roomsCapacity?: string;
  maxAdSpend?: boolean;
  isDraft?: boolean;

  invoicedAds?: number;
  qts?: number;
  flatFee?: number;
  metaAds?: number;
  otherAds?: number;
  totalInvoices?: number;
  costLead?: number;
  grossProfit?: number;
  invoicedAdsD2?: number;

  qtsD2?: number;
  flatFeeD2?: number;
  totalInvoicesD2?: number;
  costLeadD2?: number;
  grossProfitD2?: number;

  financeStrRow: string;
  adsStrRow: string;
  toDoStrRow: string;
  payStatsStrRow: string;
  overviewStrRow: string;
  newVenue?: boolean;
  eventType?: 'workshopsSeminarType' | 'lunchDinnerType' | 'webinarType';
  confirmationStatus?: string;
  confirmationStatusD2?: string;
  avgAttendance?: number;
  rescheduledFromId?: string;
  rescheduledFromIndex?: number;

  firstReschedules: IEventSchedule[];
  secondReschedules: IEventSchedule[];
  reschedules: IEventSchedule[];
  use_catchlight: boolean;
  use_catchlight_2: boolean;
  startTime: string;
  startTime2: string;

  utC_Offset_Date?: number;
  utC_Offset_Date2?: number;
}
export interface IEventOption {
  id: string;
  title: string;
}

export const EventScheduleSortOptions: SortOption[] = [
  { label: $localize`:Sort label Topic:Topic`, property: 'topic' },
  { label: $localize`:Sort label Date:Date`, property: 'date' },
];

@Injectable({ providedIn: 'root' })
export class EventScheduleRepository extends BaseRepository<IEventSchedule> {
  name = store.name;

  //  upcoming calls store

  upcomingMonths$ = upcomingStore.pipe(selectAllEntities());
  upcomingActiveMonth?: IMonthWithEvents;
  updateUpcomingOne(model: IMonthWithEvents) {
    upcomingStore.update(
      updateRequestCache(model.id),
      upsertEntities([model]),
      updateRequestsStatus([model.id], 'success')
    );
  }
  filterCallsByTeam(team?: string) {
    if (this.upcomingActiveMonth?.showEventSchedules) {
      if (!team) {
        this.upcomingActiveMonth!.showEventSchedules =
          this.upcomingActiveMonth!.eventSchedules;
        return;
      }
      this.upcomingActiveMonth!.showEventSchedules = filter(
        this.upcomingActiveMonth?.eventSchedules,
        (x) =>
          x.wfrCall1FunnelId == team ||
          x.wfrCall2FunnelId == team ||
          x.wfrCall1D2FunnelId == team ||
          x.wfrCall2D2FunnelId == team
      );
    }
  }
  updateUpcomingMonths(months: IMonthWithEvents[]) {
    upcomingStore.update(deleteAllEntities());
    upcomingStore.update(
      upsertEntities(
        months.map((x) => ({
          ...x,
          showEventSchedules: x.eventSchedules,
        }))
      )
    );
  }
  setUpcomingActivemonth(name: string) {
    this.upcomingMonths$.subscribe((months) => {
      let searchedMonth = months?.find((m) => {
        return m.name == name;
      });

      if (searchedMonth) {
        this.upcomingActiveMonth = searchedMonth;
      }
    });
  }
  pendingEventsCountSubject = new BehaviorSubject<string | undefined>(
    undefined
  );

  getPendingEventsCount(): Observable<string | undefined> {
    return this.pendingEventsCountSubject.asObservable();
  }

  updatePendingEventsCount(newValue: string): void {
    if (+newValue > 99) newValue = '99+';
    if (+newValue <= 0) newValue = '';
    this.pendingEventsCountSubject.next(newValue);
  }

  clearUpcomingMonths() {
    upcomingStore.update(deleteAllEntities());
  }

  //  backend calls store
  backendMonths$ = backendStore.pipe(selectAllEntities());
  backendActiveMonth?: IMonthWithEvents;
  updateBackendOne(model: IMonthWithEvents) {
    backendStore.update(
      updateRequestCache(model.id),
      upsertEntities([model]),
      updateRequestsStatus([model.id], 'success')
    );
  }
  filterBackendByTeam(team?: string) {
    if (this.backendActiveMonth?.showEventSchedules) {
      if (!team) {
        this.backendActiveMonth!.showEventSchedules =
          this.backendActiveMonth!.eventSchedules;
        return;
      }
      this.backendActiveMonth!.showEventSchedules = filter(
        this.backendActiveMonth?.eventSchedules,
        (x) =>
          x.funnelId == team ||
          x.funnelQAbyId == team ||
          x.funnelAdsId == team ||
          x.funnelAdsQAbyId == team ||
          x.funnelSecondId == team ||
          x.funnelSecondQAbyId == team ||
          x.funnelSecondAdsId == team ||
          x.funnelSecondAdsQAbyId == team
      );
    }
  }
  updateBackendMonths(months: IMonthWithEvents[]) {
    backendStore.update(deleteAllEntities());
    backendStore.update(
      upsertEntities(
        months.map((x) => ({
          ...x,
          showEventSchedules: x.eventSchedules,
        }))
      )
    );
  }
  setBackendActivemonth(name: string) {
    this.backendMonths$.subscribe((months) => {
      let searchedMonth = months?.find((m) => {
        return m.name == name;
      });
      if (searchedMonth) {
        this.backendActiveMonth = searchedMonth;
      }
    });
  }
  clearBackendMonths() {
    backendStore.update(deleteAllEntities());
  }

  //  pending months store
  months$ = store.pipe(selectAllEntities());
  updateOneMonth(model: IMonthWithEvents) {
    store.update(
      updateRequestCache(model.id),
      upsertEntities([model]),
      updateRequestsStatus([model.id], 'success')
    );
  }
  updateMonths(months: IMonthWithEvents[]) {
    months = months.map((month) => {
      return {
        ...month,
        showEventSchedules: month.eventSchedules?.slice(0, 40),
      };
    });
    store.update(deleteAllEntities());
    store.update(upsertEntities(months));
  }
  clearMonths() {
    store.update(deleteAllEntities());
  }
  setLastStatusEvents(
    month: IMonthWithEvents,
    pageProperties?: IPageProperties
  ) {
    let res = [] as IEventSchedule[];
    var lastStatus = pageProperties?.monthStates.find((prop) =>
      month.name.includes(prop.name)
    )?.statusFilter;
    var lastStatusNum = pageProperties?.monthStates.find((prop) =>
      month.name.includes(prop.name)
    )?.statusFilterNum;
    if (
      lastStatus == 'Automatic' &&
      lastStatusNum != undefined &&
      lastStatusNum != null &&
      lastStatusNum != 0
    ) {
      res = month.eventSchedules?.filter(
        (event) =>
          (event.status == lastStatus && +event.statusNum == lastStatusNum) ||
          (event.doubleEvent &&
            event.statusD2 == lastStatus &&
            +event.statusNumD2 == lastStatusNum)
      );
    } else if (lastStatus) {
      res = month.eventSchedules?.filter(
        (event) =>
          event.status == lastStatus ||
          (event.doubleEvent && event.statusD2 == lastStatus)
      );
    } else {
      res = month.eventSchedules;
    }
    return res;
  }
  readonly Default_LocationId = '83199f76-ccf5-4162-8e44-650bae6a1c76';
  readonly Default_Location_Error = 'Please select non default location';

  constructor() {
    super('EventScheduleOptions', EventScheduleSortOptions);
  }
}
